import React from "react"

import Layout from "../components/Layout"
import Link from "../components/Link"

const NotFoundPage = () => {
  return (
    <Layout>
      <h1>Not Found</h1>
      <p>You found a dead link...</p>
      <p>
        <Link to="/">Homepage</Link>
      </p>
    </Layout>
  )
}

export default NotFoundPage
